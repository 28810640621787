<app-navigation></app-navigation>

 
<div class="container p-4">
  <div class="row">
    <div class="col-md-5 text-center">
      <app-task-form></app-task-form>
    </div>
    <div class="col-md-7">
      <app-task-list></app-task-list>
    </div>
  </div>
</div>
 

<router-outlet></router-outlet>
