<div class="card mb-2">
    <div class="card-header d-flex justify-content-between align-items-center">
        <h3>{{ task.title }}</h3>
        <div>
            <button (click)="task.hide = !task.hide" class="btn btn-info m-2">
                Mostrar
            </button>
            <button (click)="deleteTask(task)" class="btn btn-warning  m-2">
                Eliminar
            </button>
        </div>
    </div>
    <div class="card-body" [hidden]="task.hide">
        <p>{{task.description}}</p>
    </div>
</div>
